var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(false)?_c('div',{staticClass:"form-page"},[_c('div',{staticClass:"prohibit"},[_c('img',{attrs:{"src":require("@/assets/img/prohibit.png"),"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.title))])])]):_c('div',{staticClass:"form-page"},[_c('choiceLang'),_c('div',{staticClass:"form-title"},[_c('span',{staticClass:"text"},[(_vm.lang === 'zh')?_c('span',[_vm._v(_vm._s(_vm.restaurantCnrDesign.TitleChinese))]):_vm._e(),(_vm.lang === 'en')?_c('span',[_vm._v(_vm._s(_vm.restaurantCnrDesign.TitleEnglish))]):_vm._e()])]),_c('div',{staticClass:"form-wrap"},[_c('div',{staticClass:"title"},[(_vm.lang === 'zh')?_c('span',[_vm._v("餐廳防疫座位表 - "+_vm._s(_vm.currentPlace.BuildingChinese)+_vm._s(_vm.currentPlace.AreaChinese)+" ")]):_vm._e(),(_vm.lang === 'en')?_c('span',[_vm._v("Restaurant Epidemic Prevention Seating Table_Building "+_vm._s(_vm.currentPlace.BuildingEnglish)+"-"+_vm._s(_vm.currentPlace.AreaEnglish))]):_vm._e()]),_c('div',{staticClass:"desc"},[(_vm.lang === 'zh')?_c('span',[_vm._v(_vm._s(_vm.restaurantCnrDesign.DescriptionChinese))]):_vm._e(),(_vm.lang === 'en')?_c('span',[_vm._v(_vm._s(_vm.restaurantCnrDesign.DescriptionEnglish))]):_vm._e()]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var changed = ref.changed;
var validated = ref.validated;
var reset = ref.reset;
return [_c('form',{key:_vm.index,on:{"~submit":function($event){$event.preventDefault();return _vm.addRestaurantCnr.apply(null, arguments)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"工號","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staffInfo.BadgeNo),expression:"staffInfo.BadgeNo"}],attrs:{"type":"text","placeholder":_vm.i18n('PlaceHolder.BadgeNumber')},domProps:{"value":(_vm.staffInfo.BadgeNo)},on:{"blur":_vm.getStaffName,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staffInfo, "BadgeNo", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"姓名","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staffInfo.Name),expression:"staffInfo.Name"}],attrs:{"type":"text","placeholder":_vm.i18n('PlaceHolder.Name'),"readonly":""},domProps:{"value":(_vm.staffInfo.Name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staffInfo, "Name", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"座位號碼","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.seatNoOptions,"label":"SeatNo","placeholder":_vm.i18n('PlaceHolder.SeatNumber'),"reduce":function (seatOption) { return seatOption.Guid; }},model:{value:(_vm.questionnaire.SeatNo),callback:function ($$v) {_vm.$set(_vm.questionnaire, "SeatNo", $$v)},expression:"questionnaire.SeatNo"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.questionnaire.Reason),expression:"questionnaire.Reason"}],attrs:{"type":"text","placeholder":_vm.i18n('PlaceHolder.DesignatedSeat')},domProps:{"value":(_vm.questionnaire.Reason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.questionnaire, "Reason", $event.target.value)}}})]),_c('div',{staticClass:"field"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.questionnaire.Suggest),expression:"questionnaire.Suggest"}],attrs:{"type":"text","placeholder":_vm.i18n('PlaceHolder.Suggestion')},domProps:{"value":(_vm.questionnaire.Suggest)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.questionnaire, "Suggest", $event.target.value)}}})]),_c('div',{staticClass:"button-wrap"},[_c('button',{staticClass:"btn btn-warning",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.i18n("Buttons.Submit")))]),((changed && !valid && validated) || (!changed && !valid && validated))?_c('span',{staticClass:"btn text-danger",staticStyle:{"cursor":"default"}},[_vm._v("尚有必填欄位未填到 There are still required fields not filled in")]):_vm._e()])])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }