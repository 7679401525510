<template>
  <div>
    <div class="form-page" v-if="false">
        <div class="prohibit">
            <img src="@/assets/img/prohibit.png" alt="">
            <p>{{ title }}</p>
        </div>
    </div>

    <div class="form-page" v-else>

      <choiceLang />

      <div class="form-title">
        <span class="text">
          <span v-if="lang === 'zh'">{{ restaurantCnrDesign.TitleChinese }}</span>
          <span v-if="lang === 'en'">{{ restaurantCnrDesign.TitleEnglish }}</span>
        </span>
        <!-- <img src="@/assets/img/drinking_guy.png" alt="" /> -->

        
      </div>
      <!-- <el-steps :active="active" finish-status=  "success" simple style="margin-top: 20px">
              <el-step v-for="i in 6" :key="i" :title="'步驟'+i" ></el-step>
          </el-steps>


          <el-button style="margin-top: 12px;" @click="next">下一步</el-button> -->

      <div class="form-wrap">
        <div class="title">
            <span v-if="lang === 'zh'">餐廳防疫座位表 - {{ currentPlace.BuildingChinese }}{{ currentPlace.AreaChinese }} </span>
            <span v-if="lang === 'en'">Restaurant Epidemic Prevention Seating Table_Building {{ currentPlace.BuildingEnglish }}-{{ currentPlace.AreaEnglish }}</span>
          </div>
          <div class="desc">
            <!-- 同仁您好：<br>
            因應COVID-19防疫措施，請於入座時，填寫您的工號及座位號碼<br> -->
            <span v-if="lang === 'zh'">{{ restaurantCnrDesign.DescriptionChinese }}</span>
            <span v-if="lang === 'en'">{{ restaurantCnrDesign.DescriptionEnglish }}</span>
            <!-- In response to the COVID-19 epidemic prevention measures, please fill in your badge number and seat number when you are seated. -->
          </div>

          <ValidationObserver v-slot="{ valid, changed, validated, reset }" ref="form">
            <form
              :key="index"
              @submit.prevent.once="addRestaurantCnr"
              @reset.prevent="reset"
            >
              
              <div class="field">
                <ValidationProvider
                  name="工號"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input type="text" :placeholder="i18n('PlaceHolder.BadgeNumber')" v-model="staffInfo.BadgeNo" @blur="getStaffName" />
                  <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                  </span>
                </ValidationProvider>
                
              </div>
              <div class="field">
                <ValidationProvider
                  name="姓名"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input type="text" :placeholder="i18n('PlaceHolder.Name')" v-model="staffInfo.Name" readonly />
                  <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="field">
                <ValidationProvider
                  name="座位號碼"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                      :options="seatNoOptions"
                      label="SeatNo"
                      :placeholder="i18n('PlaceHolder.SeatNumber')"
                      :reduce="(seatOption) => seatOption.Guid"
                      v-model="questionnaire.SeatNo"
                  >
                  </v-select>
                  <!-- <input type="text" :placeholder="i18n('PlaceHolder.SeatNumber')" v-model="questionnaire.SeatNo" /> -->
                  <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="field">
                <input type="text" :placeholder="i18n('PlaceHolder.DesignatedSeat')" v-model="questionnaire.Reason" />
              </div>
              <div class="field">
                <input type="text" :placeholder="i18n('PlaceHolder.Suggestion')" v-model="questionnaire.Suggest" />
              </div>
              
              <div class="button-wrap">        
                <button type="submit" class="btn btn-warning">{{ i18n("Buttons.Submit") }}</button>
                <span style="cursor: default;" v-if="(changed && !valid && validated) || (!changed && !valid && validated)" class="btn text-danger">尚有必填欄位未填到 There are still required fields not filled in</span>
              </div>
            </form>
          </ValidationObserver>

      </div>
    </div>

  </div>
  
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { apiGetStaffName } from '@/apis/common'
import { apiAddRestaurantCnr } from '@/apis/questionnaire'
import choiceLang from '@/components/common/choiceLang.vue'

export default {
  components: {
    choiceLang
  },
  data() {
    return {
      staffInfo: {
        BadgeNo: null,  //  工號
        Name: null,
      },
      questionnaire: {
        // Area: 'A棟_黃區',
        SeatNo: '',
        Reason: null,
        Suggest: null,
      },
      placeId: '',
      currentPlace: {},
      place: [
        {
          id: 'A-yellow Area',
          name: 'A棟黃區',
        },
        {
          id: 'A-green Area',
          name: 'A棟綠區',
        },
        {
          id: 'A-blue Area',
          name: 'A棟藍區',
        },
        {
          id: 'B-yellow Area',
          name: 'B棟黃區',
        },
        {
          id: 'B-green Area',
          name: 'B棟綠區',
        },
        {
          id: 'B-blue Area',
          name: 'B棟藍區',
        },
        {
          id: 'L-yellow Area',
          name: 'L棟黃區',
        },
        {
          id: 'L-green Area',
          name: 'L棟綠區',
        },
        {
          id: 'L-blue Area',
          name: 'L棟藍區',
        }
      ],
      seatNoOptions: [],

      title: '',
      index: 0, //  避免被once後，無法再次送出
    };
  },
  computed: {
    ...mapGetters(['restaurantCnrDesign', 'lang'])
  },
  watch: {
    "questionnaire.SeatNo": function() {
      // let seat = this.seatNoOptions.filter(item => {
      //   return item.Guid = this.questionnaire.SeatNo
      // })

      // this.currentPlace.Seat = seat[0].SeatNo
    }
  },
  methods: {
    ...mapActions(['getRestaurantCnrDesign']),
    ///////////////////////////  多國語系  ///////////////////////////////////////////
    i18n (keyStr) {
      return this.$t(keyStr);
    },
    getStaffName() {
      apiGetStaffName({
        badgeNo: this.staffInfo.BadgeNo
      }).then((response) => {

        if(response.status === 200) {
          this.staffInfo.Name = response.data
        }
      })
      .catch(() => {
        this.staffInfo.BadgeNo = null
        this.$refs.form.reset();
      })
    },
    async addRestaurantCnr() {

      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return
      }

      apiAddRestaurantCnr({
        ...this.staffInfo,
        ...this.questionnaire,
        Area: `${this.currentPlace.BuildingChinese}${this.currentPlace.AreaChinese}`
      }).then((response) => {
        console.log(response)

        if(response.status === 200) {

          let seat = this.seatNoOptions.filter(item => {
            return item.Guid === this.questionnaire.SeatNo
          })

          console.log(seat)

          this.questionnaire.Seat = seat[0].SeatNo

          this.$notify({
              title: '成功',
              message: '已成功送出',
              type: 'success',
              duration: 3500
          });
          
          // this.$router.push('finish')
          this.$router.push({ name: 'Finish',  params: { staff: JSON.stringify(this.staffInfo), questionnaire: JSON.stringify(this.questionnaire), currentPlace: JSON.stringify(this.currentPlace) }})
          
        }
      })
      .catch(() => {
        this.index++ ;
      })

    },
    //  取得餐廳位子資訊
    getAreaFromGuid() {
      const vm = this;
      const api = `${window.BaseUrl.api}/Questionnire/GetAreaFromGuid/${vm.placeId}`;

      // this.updateLoading(true)
      
      vm.$http.get(api).then((response) => {
            console.log(response)

            if(response.status === 200) {
              this.currentPlace = response.data
            }

            // this.updateLoading(false)
      })
      .catch(() => {
        // this.updateLoading(false)
      })
    },
    //  餐廳-依區域guid查出所有座位號碼
    getRestaurantSeatNoAllFromArea() {
      const vm = this;
      const api = `${window.BaseUrl.api}/Questionnire/GetRestaurantSeatNoAllFromArea/${vm.placeId}`;

      // this.updateLoading(true)
      
      vm.$http.get(api).then((response) => {
            console.log(response)

            if(response.status === 200) {
              this.seatNoOptions = response.data
            }

            // this.updateLoading(false)
      })
      .catch(() => {
        // this.updateLoading(false)
      })
    }
  },
  created() {
    this.placeId = this.$route.params.id

    if(this.placeId !== '') {
      this.getAreaFromGuid();
      this.getRestaurantSeatNoAllFromArea();
    }
    
    // let res = this.place.filter(item => {
    //   return item.id === placeId
    // })

    // this.currentPlace = res[0]

    this.getRestaurantCnrDesign();

    this.title = window.Disabled.title
  }
};
</script>

<style lang="scss" scoped>
.form-page {
  background-color: #bfe3bf;
  text-align: center;
  min-height: 100vh;
  padding: 90px 20px;
  // background-image: url("~@/assets/img/bg.png");
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and (max-width: 767px) {
    padding: 60px 30px;
  }

  .form-title {
    width: 400px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 30px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    img {
      width: 100%;
      max-width: 280px;
      margin: 30px;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .text {
      
      background-image: url("~@/assets/img/title_bg.png");
      padding: 10px 20px;
      border-radius: 44px;
      color: #000;
      display: inline-block;
      font-size: 2em;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        font-size: 1em;
      }
    }
    .page-number {
      span {
        font-size: 2em;
      }
    }
  }
  .form-wrap {
    // width: 550px;
    width: 560px;
    display: inline-block;
    vertical-align: middle;
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .title {
      text-align: center;
      margin-bottom: 15px;

      span {
        display: inline-block;
        background: linear-gradient(rgba(#fff, 0) 60%, #fff 0%) !important;
        font-weight: bold;
        padding: 0 15px;
      }
    }

    .desc {
      font-size: 0.9em;
      line-height: 200%;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    .field {
      input {
        border: none;
        padding: 0 10px;
        line-height: 40px;
        width: 100%;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2);
        &:focus {
          outline: none;
        }
        
      }
      /deep/ .v-select {
        border: none;
        line-height: 30px;
        width: 100%;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: inset 2px 2px 2px argb(0, 0, 0, 20%);
        background-color: white;
      }

      /deep/ .vs__search {
        color: gray;
      }
    }

    .checkbox {
      display: block;
      cursor: pointer;

      .text {
        &:after {
          content: "\f058";
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          color: #b6d3b6;
          position: absolute;
          left: 10px;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
          color: #d7e995;
          font-size: 1.2em;
        }

        position: relative;
        background-color: #97bd97;
        line-height: 40px;
        display: block;
        padding: 0 10px;
        padding-left: 40px;
        border-radius: 35px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        color: #fff;
      }

      input {
        display: none;
        &:checked {
          & + .text {
            background-color: #039577;
            color: #fff;
            &:after {
              color: #fff;
            }
          }
        }
      }
    }

    textarea {
      border: none;
      padding: 10px;
      width: 100%;
      margin-bottom: 10px;
      border-radius: 5px;
      box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2);
    }

    .button-wrap {
      margin-top: 30px;
    }
  }
}

.prohibit {
    width: 500px;
    display: inline-block;
    // vertical-align: middle;
    padding: 0 30px;

    @media screen and (max-width: 767px) {
        width: 100%;
    }

    img {
        width: 100%;
        // max-width: 280px;
        margin-bottom: 30px;
    }

    p {
        width: 100%;
        font-size: 1.25rem;
        color: #5a5c69!important;
        font-weight: 900;
    }
}
</style>
